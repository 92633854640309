import React, { useContext, useState } from 'react';
import { Box, Menu, MenuItem, Avatar, IconButton, Tooltip, Button, Typography, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, useLocation } from 'react-router-dom';
import { LanguageContext, AuthContext } from '../App';
import { Settings as SettingsIcon, Help as HelpIcon} from '@mui/icons-material';
import LogoutConfirmDialog from './LogoutConfirmDialog.tsx';

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.background.default,
  borderBottom: `1px solid ${theme.palette.divider}`,
  width: '100%',
  boxSizing: 'border-box',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1100,
  boxShadow: theme.palette.mode === 'light' ? '0 2px 4px rgba(0,0,0,0.1)' : 'none',
  transition: 'background-color 0.3s ease',
}));

const Logo = styled('img')({
  height: '40px',
  cursor: 'pointer',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const Controls = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  cursor: 'pointer',
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.1)',
  },
  border: `2px solid ${theme.palette.primary.main}`,
}));

const FeatureTitle = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  fontWeight: 'bold',
  fontSize: '1.5rem',
  color: theme.palette.text.primary,
}));

const Header = () => {
  const { t } = useContext(LanguageContext);
  const { isAuthenticated, handleLogout, user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleClose();
  };

  const handleLogoutClick = () => {
    handleClose();
    setLogoutDialogOpen(true);
  };

  const handleLogoutConfirm = () => {
    setLogoutDialogOpen(false);
    handleLogout();
    navigate('/login');
  };

  const getUserInitials = () => {
    if (user && user.full_name) {
      return user.full_name.split(' ').map(n => n[0]).join('').toUpperCase();
    }
    return 'U';
  };

  const getFeatureTitle = () => {
    const path = location.pathname;
    
    if (path === '/chat') return t('aqiliBaro');
    if (path === '/openai') return t('aqiliOpenAI');
    if (path === '/gemini') return t('aqiliGemini');
    if (path === '/search' || path === '/images') return t('aqiliImageGenerator');
    if (path.includes('/book')) return t('Create Book');
    return null;
  };

  const renderControls = () => (
    <>
      <Tooltip title={t('help')}>
        <IconButton onClick={() => navigate('/help')} color="primary">
          <HelpIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('settings')}>
        <IconButton onClick={() => navigate('/settings')} color="primary">
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      {isAuthenticated ? (
        <>
          <StyledAvatar
            onClick={handleClick}
            alt={user?.full_name || t('profileImage')}
            src={user?.profile_picture || ''}
          >
            {(!user?.profile_picture || user.profile_picture === '') && getUserInitials()}
          </StyledAvatar>
          {!isMobile && (
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              {user?.full_name || user?.username}
            </Typography>
          )}
        </>
      ) : (
        <>
          {!isMobile && (
            <>
              <Button onClick={() => navigate('/login')} color="primary">{t('signIn')}</Button>
              <Button onClick={() => navigate('/signup')} color="primary">{t('signUp')}</Button>
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <HeaderContainer>
      <Logo src="/logheader.png" alt={t('companyLogo')} onClick={() => navigate('/')} />
      
      {/* Feature Title */}
      {getFeatureTitle() && (
        <FeatureTitle variant="h5">
          {getFeatureTitle()}
        </FeatureTitle>
      )}

      {isMobile ? (
        <Controls>
          {renderControls()}
        </Controls>
      ) : (
        <Controls>
          {renderControls()}
        </Controls>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => handleNavigation('/account')}>{t('profile')}</MenuItem>
        <MenuItem onClick={() => handleNavigation('/settings')}>{t('settings')}</MenuItem>
        <MenuItem onClick={handleLogoutClick}>{t('logout')}</MenuItem>
      </Menu>
      <LogoutConfirmDialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        onConfirm={handleLogoutConfirm}
        t={t}
      />
    </HeaderContainer>
  );
};

export default Header;