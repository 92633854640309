import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { LanguageContext, AuthContext } from '../App';
import { Box, Button, IconButton, Popper, Paper, ClickAwayListener, Tooltip, Badge, Typography, Avatar, Menu, MenuItem, Drawer } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { FaCog, FaQuestionCircle, FaComments, FaUser, FaBook, FaPlus, FaList } from 'react-icons/fa'; 
import CloseIcon from '@mui/icons-material/Close';

import LogoutConfirmDialog from './LogoutConfirmDialog.tsx';
import Help from './Help';

const SidebarMenu = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    overflowY: 'auto',
    padding: '0 16px',
});

const SidebarButton = styled(Button)(({ theme, active }) => ({
    backgroundColor: active === 'true' ? theme.palette.action.selected : 'transparent',
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
    borderRadius: theme.shape.borderRadius,
    justifyContent: 'flex-start',
    textAlign: 'left',
    width: '100%',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    position: 'relative',
}));

const SubMenuItem = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.1)',
    },
    border: `2px solid ${theme.palette.primary.main}`,
}));

const UserInfoContainer = styled(Box)(({ theme, isMobile }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const Sidebar = ({ isMobile }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [bookAnchorEl, setBookAnchorEl] = useState(null);
    const [chatAnchorEl, setChatAnchorEl] = useState(null);
    const theme = useTheme();
    const { t } = useContext(LanguageContext);
    const { isAuthenticated, user, handleLogout } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

    useEffect(() => {
        // Simulating fetching unread notifications
        setUnreadNotifications(3);
    }, []);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleSubmenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSubmenuClose = () => {
        setAnchorEl(null);
    };

    const handleBookSubmenuOpen = (event) => {
        setBookAnchorEl(event.currentTarget);
    };

    const handleBookSubmenuClose = () => {
        setBookAnchorEl(null);
    };

    const handleChatSubmenuOpen = (event) => {
        setChatAnchorEl(event.currentTarget);
    };

    const handleChatSubmenuClose = () => {
        setChatAnchorEl(null);
    };

    const handleNavigation = (path) => {
        navigate(path);
        handleSubmenuClose();
        handleBookSubmenuClose();
        handleChatSubmenuClose();
        if (isMobile) {
            setSidebarOpen(false);
        }
    };

    const isActive = (path) => location.pathname === path;

    const handleUserMenuOpen = (event) => {
        setUserMenuAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = () => {
        setUserMenuAnchorEl(null);
    };

    const handleLogoutClick = () => {
        handleUserMenuClose();
        setLogoutDialogOpen(true);
    };

    const handleLogoutConfirm = () => {
        setLogoutDialogOpen(false);
        handleLogout();
        navigate('/login');
    };

    const getUserInitials = () => {
        if (user && user.full_name) {
            return user.full_name.split(' ').map(n => n[0]).join('').toUpperCase();
        }
        return 'U';
    };

    const menuItems = [
        { icon: FaComments, label: 'Chat', subMenu: true },
        { icon: FaBook, label: 'Books', path: '/books', subMenu: true },
        { icon: FaUser, label: 'account', path: '/account', authRequired: true },
        { icon: FaCog, label: 'settings', path: '/settings' },
        { icon: FaQuestionCircle, label: 'help', path: '/help', component: Help },
    ];

    const renderSidebarContent = () => (
        <Box sx={{ width: 250, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center',
                padding: theme.spacing(2),
                marginTop: '64px',
            }}>
                <Typography variant={isMobile ? "h6" : "h5"} component="h2">
                    {t('Menu')}
                </Typography>
                <IconButton onClick={toggleSidebar}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <SidebarMenu>
                <Box sx={{ position: 'relative', width: '100%' }}>
                    <Tooltip title={t('aqiliImageGenerator')} placement="right" arrow disableHoverListener={isMobile}>
                        <SidebarButton 
                            onClick={handleSubmenuOpen}
                            aria-describedby="submenu-popper"
                            active={(isActive('/search') || isActive('/images')).toString()}
                        >
                            <img
                                src="/aqililogorgb.png"
                                alt="aqili"
                                style={{ width: '20px', height: '20px', marginRight: theme.spacing(1) }}
                            />
                            {t('aqiliImageGenerator')}
                        </SidebarButton>
                    </Tooltip>
                    <Popper
                        id="submenu-popper"
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        placement="right-start"
                        style={{ zIndex: 1500 }}
                    >
                        <ClickAwayListener onClickAway={handleSubmenuClose}>
                            <Paper sx={{ boxShadow: theme.shadows[3] }}>
                                <Box p={1}>
                                    <SubMenuItem onClick={() => handleNavigation('/search')}>{t('generateImages')}</SubMenuItem>
                                    <SubMenuItem onClick={() => handleNavigation('/images')}>{t('viewAllImages')}</SubMenuItem>
                                </Box>
                            </Paper>
                        </ClickAwayListener>
                    </Popper>
                </Box>

                {menuItems.map((item, index) => (
                    <React.Fragment key={index}>
                        {(!item.authRequired || (item.authRequired && isAuthenticated)) && (
                            <Box sx={{ position: 'relative', width: '100%' }}>
                                <Tooltip title={t(item.label)} placement="right" arrow disableHoverListener={isMobile}>
                                    <SidebarButton 
                                        onClick={item.subMenu ? 
                                            (item.label === 'Chat' ? handleChatSubmenuOpen : 
                                             item.label === 'Books' ? handleBookSubmenuOpen : 
                                             () => handleNavigation(item.path))
                                            : () => handleNavigation(item.path)}
                                        active={(isActive(item.path) || 
                                            (item.label === 'Chat' && (isActive('/chat') || isActive('/openai') || isActive('/gemini')))).toString()}
                                    >
                                        <item.icon style={{ marginRight: theme.spacing(1) }} />
                                        {t(item.label)}
                                        {item.label === 'account' && unreadNotifications > 0 && (
                                            <Badge badgeContent={unreadNotifications} color="error" sx={{ ml: 1 }} />
                                        )}
                                    </SidebarButton>
                                </Tooltip>
                            </Box>
                        )}
                    </React.Fragment>
                ))}

                <Popper
                    id="chat-submenu-popper"
                    open={Boolean(chatAnchorEl)}
                    anchorEl={chatAnchorEl}
                    placement="right-start"
                    style={{ zIndex: 1500 }}
                >
                    <ClickAwayListener onClickAway={handleChatSubmenuClose}>
                        <Paper sx={{ boxShadow: theme.shadows[3] }}>
                            <Box p={1}>
                                <SubMenuItem onClick={() => {
                                    handleNavigation('/chat');
                                    handleChatSubmenuClose();
                                }}>
                                    <img
                                        src="/aqililogorgb.png"
                                        alt="aqili"
                                        style={{ width: '16px', height: '16px', marginRight: theme.spacing(1) }}
                                    />
                                    {t('aqili')}
                                </SubMenuItem>
                                <SubMenuItem onClick={() => {
                                    handleNavigation('/openai');
                                    handleChatSubmenuClose();
                                }}>
                                    <img
                                        src="/chatgpt-icon.svg"
                                        alt="OpenAI"
                                        style={{ width: '16px', height: '16px', marginRight: theme.spacing(1) }}
                                    />
                                    {t('OpenAI')}
                                </SubMenuItem>
                                <SubMenuItem onClick={() => {
                                    handleNavigation('/gemini');
                                    handleChatSubmenuClose();
                                }}>
                                    <img
                                        src="/google-gemini-icon.svg"
                                        alt="Gemini"
                                        style={{ width: '16px', height: '16px', marginRight: theme.spacing(1) }}
                                    />
                                    {t('Gemini')}
                                </SubMenuItem>
                            </Box>
                        </Paper>
                    </ClickAwayListener>
                </Popper>

                <Popper id="book-submenu-popper" open={Boolean(bookAnchorEl)} anchorEl={bookAnchorEl} placement="right-start" style={{ zIndex: 1500 }}>
                    <ClickAwayListener onClickAway={handleBookSubmenuClose}>
                        <Paper sx={{ boxShadow: theme.shadows[3] }}>
                            <Box p={1}>
                                <SubMenuItem onClick={() => handleNavigation('/book')}>
                                    <FaPlus style={{ marginRight: theme.spacing(1) }} />
                                    {t('Create Book')}
                                </SubMenuItem>
                                <SubMenuItem onClick={() => handleNavigation('/books')}>
                                    <FaList style={{ marginRight: theme.spacing(1) }} />
                                    {t('View Books')}
                                </SubMenuItem>
                            </Box>
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </SidebarMenu>

            <UserInfoContainer isMobile={isMobile}>
                {isAuthenticated && user && (
                    <Tooltip title={user.full_name || user.username} placement="right">
                        <StyledAvatar
                            onClick={handleUserMenuOpen}
                            alt={user.full_name || t('profileImage')}
                            src={user.profile_picture || ''}
                            sx={{ width: 32, height: 32 }}
                        >
                            {(!user.profile_picture || user.profile_picture === '') && getUserInitials()}
                        </StyledAvatar>
                    </Tooltip>
                )}
                {isAuthenticated && user && (
                    <Typography variant="body2" sx={{ ml: 1 }}>
                        {user.full_name || user.username}
                    </Typography>
                )}
            </UserInfoContainer>
        </Box>
    );

    const handleMouseEnter = () => {
        setSidebarOpen(true);
    };

    const handleMouseLeave = () => {
        setSidebarOpen(false);
    };

    return (
        <>
            <Tooltip title={t('Menu')} placement="right">
                <Box
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={toggleSidebar}
                    sx={{
                        position: 'fixed',
                        top: '74px',
                        left: theme.spacing(1),
                        zIndex: 1000,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        cursor: 'pointer',
                        padding: '8px',
                        borderRadius: '4px',
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    }}
                >
                    <img
                        src="/aqililogorgb.png"
                        alt="aqili"
                        style={{ 
                            width: '24px', 
                            height: '24px',
                        }}
                    />
                    <Typography 
                        variant="h6"
                        sx={{ 
                            color: theme.palette.text.primary,
                            fontWeight: 'bold',
                            fontSize: '1.2rem',
                            letterSpacing: '0.5px',
                            
                        }}
                    >
                        aqili
                    </Typography>
                </Box>
            </Tooltip>

            <Drawer
                anchor="left"
                open={sidebarOpen}
                onClose={toggleSidebar}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: 250,
                        backgroundColor: theme.palette.background.paper,
                        boxSizing: 'border-box',
                        marginTop: '64px',
                        height: 'calc(100% - 64px)',
                        zIndex: 1000,
                    },
                }}
            >
                {renderSidebarContent()}
            </Drawer>

            <Menu
                anchorEl={userMenuAnchorEl}
                open={Boolean(userMenuAnchorEl)}
                onClose={handleUserMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => handleNavigation('/account')}>{t('profile')}</MenuItem>
                <MenuItem onClick={() => handleNavigation('/settings')}>{t('settings')}</MenuItem>
                <MenuItem onClick={handleLogoutClick}>{t('logout')}</MenuItem>
            </Menu>

            <LogoutConfirmDialog
                open={logoutDialogOpen}
                onClose={() => setLogoutDialogOpen(false)}
                onConfirm={handleLogoutConfirm}
                t={t}
            />
        </>
    );
};

export default Sidebar;