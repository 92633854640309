// src/translations.js

export const translations = {
    en: {

        failedToFetchChatHistory: 'Failed to fetch chat history: ',
    failedToDeleteChat: 'Failed to delete chat: ',
    failedToDeleteChatTryAgain: 'Failed to delete chat. Please try again.',
    
        goToAccountSettings: "Go to Account Settings",
        select_theme: "Select Theme",
        select_language: "Select Language",
      


        // New translations from Signup.js
        createYourAccount: "Create Your Account",
        googleSignupError: "An error occurred during Google signup",
        microsoftSignupError: "An error occurred during Microsoft signup",
        signUpWithGoogle: "Sign up with Google",
        passwordRequirements: "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.",
        passwordsDoNotMatch: "Passwords do not match.",
        enterBothNameAndUsername: "Please enter both your full name and desired username.",
        verificationCodeSent: "Please enter the verification code sent to your email.",
        emailVerifiedSuccessfully: "Email verified successfully! You can now log in.",

        // New translations from VerifyEmail.js
        verifyYourEmail: "Verify Your Email",
        emailVerifiedSuccess: "Email verified successfully. You can now log in.",
        verificationFailed: "Verification failed. Please try again.",
        verificationFailedTryAgain: "Verification failed. Please try again.",
        // Sidebar items
        generateImages: "Generate Images",
        viewImages: "View Images",
        settings: "Settings",
        help: "Help",
        chat: "Chat",
        openAI: "OpenAI",
        gemini: "Gemini",

        // Main content headers
        aqiliBaro: "aqili",
        aqiliImageGenerator: "aqili Image Generator",
        myGeneratedImages: "My Generated Images",
        helpContent: "Help Content",
        aqiliOpenAI: "OpenAI",
        aqiliGemini: "Gemini",

        // Settings
        selectTheme: "Select Theme:",
        light: "Light",
        dark: "Dark",
        selectLanguage: "Select Language:",
        english: "English",
        french: "French",
        spanish: "Spanish",
        german: "German",

        // General
        search: "Search",
        loading: "Loading...",
        error: "An error occurred",
        submit: "Submit",
        cancel: "Cancel",
        deleteImageConfirmation: "Are you sure you want to delete this image? This action cannot be undone.",
        confirmSignOutAllSessions: "Confirm Sign Out of All Sessions",
        signOutAllSessionsWarning: "Are you sure you want to sign out of all sessions? This will log you out from all devices except the current one.",
        confirm: "Confirm",
        viewAllImages: "View All Images",
        aqiliBaroGPT: "aqili baro gpt",
        aqiliOpenAIGPT: "aqili openai gpt",
        you: "You",
        assistant: "Assistant",
        askMeAnything: "Ask me anything...",
        send: "Send",
        inputEmpty: "Input cannot be empty.",
        fetchError: "Failed to fetch response from the server.",

        enterImagePrompt: "Enter image prompt",
        generateImage: "Generate Image",
        analyzeImage: "Analyze Image",
        generated: "Generated",
        analysis: "Analysis",
        sendingPrompt: "Sending prompt:",
        receivedResponse: "Received response:",
        errorGeneratingImage: "Error generating image:",
        sendingImageForAnalysis: "Sending image for analysis:",
        receivedAnalysis: "Received analysis:",
        errorAnalyzingImage: "Error analyzing image:",
        askAQuestion: "Ask a question...",
        failedToFetch: "Failed to fetch:",

        generatedImage: "Generated Image",
        errorSharing: "Error sharing",
        sharingNotSupported: "Sharing is not supported in your browser.",
        generatedImageAlt: "Generated {index}",
        selectedImage: "Selected Image",
        download: "Download",
        share: "Share",
        close: "Close",
        aqili: "aqili",
        newChat: "New Chat",

        companyLogo: "Company Logo",
        welcome: "Welcome",
        profileImage: "Profile Image",
        footerText: "©{year} aqili AI. All rights reserved.",

        account: "Account",
        profile: "Profile",
        username: "Username",
        email: "Email",
        saveChanges: "Save Changes",

        uploadPicture: "Upload Picture",
        profilePicture: "Profile Picture",

        copiedToClipboard: "Copied to clipboard",
        failedToCopy: "Failed to copy",
        copy: "Copy",
        copyCode: "Copy Code",
        retry: "Retry",
        shareFeedback: "Share Feedback",
        reportIssue: "Report Issue",
        failedToRetry: "Failed to retry the message",
        modelError: "The model did not generate a response. This may be due to safety filters or content policy.",

        feedback: "Feedback",
        whatWasSatisfying: "What was satisfying about this response?",
        feedbackDisclaimer: "Submitting this report will send the current conversation to aqili and store with your Feedback for future improvements to our models. When you submit Feedback and provide us permission, we disassociate Inputs and Outputs from your user ID to use them for training and improving our models.",
        feedbackSubmitted: "Feedback submitted successfully",

        issueType: "What type of issue do you wish to report?",
        issueDetails: "Please provide details: (optional)",
        other: "Other",
        incorrectInformation: "Incorrect Information",
        offensiveContent: "Offensive Content",
        notHelpful: "Not Helpful",
        issueReportDisclaimer: "Submitting this report will send the current conversation to aqili and store with your Feedback for future improvements to our models. When you submit Feedback and provide us permission, we disassociate Inputs and Outputs from your user ID to use them for training and improving our models.",
        issueReported: "Issue reported successfully",

        failedToSubmitFeedback: "Failed to submit feedback",
        failedToReportIssue: "Failed to report issue",

        delete: "Delete",
        shareNotSupported: "Sharing is not supported in your browser.",
        downloadImage: "Download Image",
        shareImage: "Share Image",
        deleteImage: "Delete Image",
        closeImage: "Close Image",
        imageOptions: "Image Options",

        checkOutThisImage: "Check out this image generated by AI!",
        imageTooLargeForEmail: "The image is too large to attach directly. The image URL has been copied to your clipboard instead.",
        imageUrlCopied: "Image URL copied to clipboard",
        copyFailed: "Failed to copy image URL",
        logoutConfirmTitle: "Confirm Logout",
        logoutConfirmMessage: "Are you sure you want to log out?",
        logout: "Logout",

        failedToFetchImages: "Failed to fetch images",
        failedToFetchReports: "Failed to fetch analysis reports",
        imageDownloadedSuccessfully: "Image downloaded successfully",
        failedToDownload: "Failed to download",
        imageDeleted: "Image deleted",
        failedToDelete: "Failed to delete",
        imageAnalyzedSuccessfully: "Image analyzed successfully",
        failedToAnalyze: "Failed to analyze",
        analysisReports: "Analysis Reports",
        noAnalysisReports: "No analysis reports available",
        analysisReport: "Analysis Report",
        analyzing: "Analyzing...",
        analyze: "Analyze",

        fullName: "Full Name",
        profileUpdatedSuccessfully: "Profile updated successfully",
        errorUpdatingProfile: "Error updating profile",
        profilePictureUpdatedSuccessfully: "Profile picture updated successfully",
        errorUploadingProfilePicture: "Error uploading profile picture",
        errorSigningOut: "Error signing out",
        errorDeletingAccount: "Error deleting account",
        system: "System",
        activeAccount: "Active Account",
        signedInAs: "You are signed in as:",
        signOut: "Sign Out",
        sessions: "Sessions",
        devicesCurrentlySignedIn: "Devices or browsers where you are currently signed in",
        signOutAllSessions: "Sign out of all sessions",
        deleteAccount: "Delete Account",
        deleteAccountDescription: "Permanently delete your account and all associated data",
        confirmAccountDeletion: "Confirm Account Deletion",
        deleteAccountWarning: "Are you sure you want to delete your account? This action cannot be undone.",
        confirmDelete: "Confirm Delete",

        // New translations for ContentSearch.js
        contentPolicyViolation: "Your request was rejected due to content safety concerns. Please try rephrasing your prompt or using different imagery. If you believe this is a mistake, you can try again or contact support.",
        badRequestError: "There was an issue with your request. Please check your prompt and try again. If the problem persists, the system may be experiencing temporary issues.",
        generalImageGenerationError: "An error occurred while generating the image. Please try again later. If the problem persists, please contact support.",
        rephrasePrompt: "Try rephrasing your prompt or using different imagery.",
        checkGuidelines: "Review our content guidelines to ensure your request complies with our policies.",
        tryAgainLater: "If the issue persists, try again later or contact support.",
        errorLoadingImage: "Error loading image",
        generalError: "An error occurred",

        // New translations for ErrorMessage.js
        errorTitle: "Error",
        suggestionsTitle: "Suggestions:",

        // New translations for Footer.js
        termsOfService: "Terms of Service",
        privacyPolicy: "Privacy Policy",

        // New translations for GeminiChatHistory.js and Header.js
        chatHistory: "Chat History",
        noChatHistoryFound: "No chat history found.",
        untitledChat: "Untitled Chat",
        deleteChat: "Delete Chat?",
        deleteChatConfirmation: "Are you sure you want to delete this chat? This action cannot be undone.",
        signIn: "Sign In",
        signUp: "Sign Up",

        // New translations for ImageActionButtons.js
        viewFullSize: "View Full Size",
        

        // New translations for ImageAnalysis.js
        imageAnalysis: "Image Analysis",
        noAnalysisAvailable: "No analysis available for this image.",
        performAnalysis: "Perform Analysis",

        // New translations for ImagePromptHistory.js
        failedToFetchPromptHistory: "Failed to fetch prompt history",
        failedToDeletePrompt: "Failed to delete prompt",
        promptHistory: "Prompt History",
        noPromptHistory: "No prompt history",
        deletePrompt: "Delete Prompt?",
        deletePromptConfirmation: "Are you sure you want to delete this prompt? This action cannot be undone.",

        // New translations for Login.js
        microsoftInitError: "Failed to initialize Microsoft authentication",
        invalidCredentials: "Invalid credentials",
        loginError: "An error occurred during login",
        googleLoginError: "An error occurred during Google login",
        microsoftLoginError: "An error occurred during Microsoft login",
        login: "Login",
        emailAddress: "Email Address",
        password: "Password",
        dontHaveAccount: "Don't have an account?",
        createAccount: "Create Account",
        forgotPassword: "Forgot Password?", 
        or: "OR",
        signInWithGoogle: "Sign in with Google",
        continueWithMicrosoftAccount: "Continue with Microsoft Account",

       
        noChatHistory: "No chat history found.",
        deleteChatConfirm: "Are you sure you want to delete this chat? This action cannot be undone.",
        resetYourPassword: "Reset Your Password",
        requestReset: "Request Reset",
        resetPassword: "Reset Password",
        requestResetCode: "Request Reset Code",
        resetCode: "Reset Code",
        newPassword: "New Password",
        confirmNewPassword: "Confirm New Password",
    },
    fr: {

        // New translations from Settings.js
        goToAccountSettings: "Aller aux paramètres du compte",
        select_theme: "Sélectionner le thème",
        select_language: "Sélectionner la langue",
        

        // New translations from Signup.js
        createYourAccount: "Créez votre compte",
        googleSignupError: "Une erreur s'est produite lors de l'inscription Google",
        microsoftSignupError: "Une erreur s'est produite lors de l'inscription Microsoft",
        signUpWithGoogle: "S'inscrire avec Google",

        passwordRequirements: "Le mot de passe doit comporter au moins 8 caractères et contenir au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.",
        passwordsDoNotMatch: "Les mots de passe ne correspondent pas.",
        enterBothNameAndUsername: "Veuillez entrer à la fois votre nom complet et le nom d'utilisateur souhaité.",
        verificationCodeSent: "Veuillez entrer le code de vérification envoyé à votre adresse e-mail.",
        emailVerifiedSuccessfully: "E-mail vérifié avec succès ! Vous pouvez maintenant vous connecter.",

        // New translations from VerifyEmail.js
        verifyYourEmail: "Vérifiez votre e-mail",
        emailVerifiedSuccess: "E-mail vérifié avec succès. Vous pouvez maintenant vous connecter.",
        verificationFailed: "La vérification a échoué. Veuillez réessayer.",
        verificationFailedTryAgain: "La vérification a échoué. Veuillez réessayer.",

        noChatHistory: "Aucun historique de conversation trouvé.",
        deleteChatConfirm: "Êtes-vous sûr de vouloir supprimer cette conversation ? Cette action ne peut pas être annulée.",
        resetYourPassword: "Réinitialisez votre mot de passe",
        requestReset: "Demander une réinitialisation",
        resetPassword: "Réinitialiser le mot de passe",
        requestResetCode: "Demander un code de réinitialisation",
        resetCode: "Code de réinitialisation",
        newPassword: "Nouveau mot de passe",
        confirmNewPassword: "Confirmer le nouveau mot de passe",

        // Sidebar items
        generateImages: "Générer des images",
        viewImages: "Voir les images",
        settings: "Paramètres",
        help: "Aide",
        chat: "Discussion",
        openAI: "OpenAI",
        gemini: "Gemini",

        // Main content headers
        aqiliBaro: "aqili Baro GPT",
        aqiliImageGenerator: "Générateur d'images aqili",
        myGeneratedImages: "Mes images générées",
        helpContent: "Contenu d'aide",
        aqiliOpenAI: "aqili OpenAI GPT",
        aqiliGemini: "aqili Gemini",

        // Settings
        selectTheme: "Sélectionner le thème :",
        light: "Clair",
        dark: "Sombre",
        selectLanguage: "Sélectionner la langue :",
        english: "Anglais",
        french: "Français",
        spanish: "Espagnol",
        german: "Allemand",

        // General
        search: "Rechercher",
        loading: "Chargement...",
        error: "Une erreur s'est produite",
        submit: "Soumettre",
        cancel: "Annuler",
        deleteImageConfirmation: "Êtes-vous sûr de vouloir supprimer cette image ? Cette action ne peut pas être annulée.",
        confirmSignOutAllSessions: "Confirmer la déconnexion de toutes les sessions",
        signOutAllSessionsWarning: "Êtes-vous sûr de vouloir vous déconnecter de toutes les sessions ? Cela vous déconnectera de tous les appareils sauf celui-ci.",
        confirm: "Confirmer",
        viewAllImages: "Voir toutes les images",
        aqiliBaroGPT: "aqili baro gpt",
        aqiliOpenAIGPT: "aqili openai gpt",
        you: "Vous",
        assistant: "Assistant",
        askMeAnything: "Posez-moi n'importe quelle question...",
        send: "Envoyer",
        inputEmpty: "L'entrée ne peut pas être vide.",
        fetchError: "Échec de la récupération de la réponse du serveur.",

        enterImagePrompt: "Entrez une invite pour l'image",
        generateImage: "Générer une image",
        analyzeImage: "Analyser l'image",
        generated: "Générée",
        analysis: "Analyse",
        sendingPrompt: "Envoi de l'invite :",
        receivedResponse: "Réponse reçue :",
        errorGeneratingImage: "Erreur lors de la génération de l'image :",
        sendingImageForAnalysis: "Envoi de l'image pour analyse :",
        receivedAnalysis: "Analyse reçue :",
        errorAnalyzingImage: "Erreur lors de l'analyse de l'image :",
        askAQuestion: "Posez une question...",
        failedToFetch: "Échec de la récupération :",

        generatedImage: "Image générée",
        errorSharing: "Erreur de partage",
        sharingNotSupported: "Le partage n'est pas pris en charge dans votre navigateur.",
        generatedImageAlt: "Généré {index}",
        selectedImage: "Image sélectionnée",
        download: "Télécharger",
        share: "Partager",
        close: "Fermer",
        aqili: "aqili",

        newChat: "Nouvelle Conversation",

        companyLogo: "Logo de l'entreprise",
        welcome: "Bienvenue",
        profileImage: "Image de profil",
        footerText: "©{year} aqili AI. Tous droits réservés.",

        account: "Compte",
        profile: "Profil",
        username: "Nom d'utilisateur",
        email: "Email",
        saveChanges: "Sauvegarder les modifications",

        uploadPicture: "Télécharger une photo",
        profilePicture: "Photo de profil",

        copiedToClipboard: "Copié dans le presse-papiers",
        failedToCopy: "Échec de la copie",
        copy: "Copier",
        copyCode: "Copier le code",
        retry: "Réessayer",
        shareFeedback: "Partager un retour",
        reportIssue: "Signaler un problème",
        failedToRetry: "Échec de la réessai du message",
        modelError: "Le modèle n'a pas généré de réponse. Cela peut être dû à des filtres de sécurité ou à la politique de contenu.",
       
        feedback: "Retour d'information",
        whatWasSatisfying: "Qu'est-ce qui était satisfaisant dans cette réponse ?",
        feedbackDisclaimer: "En soumettant ce rapport, vous envoyez la conversation actuelle à aqili et la stockez avec votre retour d'information pour de futures améliorations de nos modèles. Lorsque vous soumettez un retour d'information et nous donnez la permission, nous dissocions les entrées et les sorties de votre ID utilisateur pour les utiliser pour la formation et l'amélioration de nos modèles.",
        feedbackSubmitted: "Retour d'information soumis avec succès",
       
        issueType: "Quel type de problème souhaitez-vous signaler ?",
        issueDetails: "Veuillez fournir des détails : (facultatif)",
        other: "Autre",
        incorrectInformation: "Information incorrecte",
        offensiveContent: "Contenu offensant",
        notHelpful: "Pas utile",
        issueReportDisclaimer: "En soumettant ce rapport, vous envoyez la conversation actuelle à aqili et la stockez avec votre retour d'information pour de futures améliorations de nos modèles. Lorsque vous soumettez un retour d'information et nous donnez la permission, nous dissocions les entrées et les sorties de votre ID utilisateur pour les utiliser pour la formation et l'amélioration de nos modèles.",
        issueReported: "Problème signalé avec succès",
        failedToSubmitFeedback: "Échec de l'envoi du commentaire",
        failedToReportIssue: "Échec du signalement du problème",

        delete: "Supprimer",
        shareNotSupported: "Le partage n'est pas pris en charge dans votre navigateur.",
        downloadImage: "Télécharger l'image",
        shareImage: "Partager l'image",
        deleteImage: "Supprimer l'image",
        closeImage: "Fermer l'image",
        imageOptions: "Options de l'image",

        checkOutThisImage: "Regardez cette image générée par l'IA !",
        imageTooLargeForEmail: "L'image est trop volumineuse pour être jointe directement. L'URL de l'image a été copiée dans votre presse-papiers à la place.",
        imageUrlCopied: "URL de l'image copiée dans le presse-papiers",
        copyFailed: "Échec de la copie de l'URL de l'image",
        logoutConfirmTitle: "Confirmer la déconnexion",
        logoutConfirmMessage: "Êtes-vous sûr de vouloir vous déconnecter ?",

        logout: "Se déconnecter",

        failedToFetchImages: "Échec de la récupération des images",
        failedToFetchReports: "Échec de la récupération des rapports d'analyse",
        imageDownloadedSuccessfully: "Image téléchargée avec succès",
        failedToDownload: "Échec du téléchargement",
        imageDeleted: "Image supprimée",
        failedToDelete: "Échec de la suppression",
        imageAnalyzedSuccessfully: "Image analysée avec succès",
        failedToAnalyze: "Échec de l'analyse",
        analysisReports: "Rapports d'analyse",
        noAnalysisReports: "Aucun rapport d'analyse disponible",
        analysisReport: "Rapport d'analyse",
        analyzing: "Analyse en cours...",
        analyze: "Analyser",

        fullName: "Nom complet",
        profileUpdatedSuccessfully: "Profil mis à jour avec succès",
        errorUpdatingProfile: "Erreur lors de la mise à jour du profil",
        profilePictureUpdatedSuccessfully: "Photo de profil mise à jour avec succès",
        errorUploadingProfilePicture: "Erreur lors du téléchargement de la photo de profil",
        errorSigningOut: "Erreur lors de la déconnexion",
        errorDeletingAccount: "Erreur lors de la suppression du compte",
        system: "Système",
        activeAccount: "Compte actif",
        signedInAs: "Vous êtes connecté en tant que :",
        signOut: "Se déconnecter",
        sessions: "Sessions",
        devicesCurrentlySignedIn: "Appareils ou navigateurs où vous êtes actuellement connecté",
        signOutAllSessions: "Se déconnecter de toutes les sessions",
        deleteAccount: "Supprimer le compte",
        deleteAccountDescription: "Supprimer définitivement votre compte et toutes les données associées",
        confirmAccountDeletion: "Confirmer la suppression du compte",
        deleteAccountWarning: "Êtes-vous sûr de vouloir supprimer votre compte ? Cette action ne peut pas être annulée.",
        confirmDelete: "Confirmer la suppression",

        // New translations for ContentSearch.js
        contentPolicyViolation: "Votre demande a été rejetée en raison de préoccupations de sécurité du contenu. Veuillez essayer de reformuler votre demande ou d'utiliser une autre image. Si vous pensez que c'est une erreur, vous pouvez réessayer ou contacter le support.",
        badRequestError: "Il y a eu un problème avec votre demande. Veuillez vérifier votre demande et réessayer. Si le problème persiste, le système peut rencontrer des problèmes temporaires.",
        generalImageGenerationError: "Une erreur s'est produite lors de la génération de l'image. Veuillez réessayer plus tard. Si le problème persiste, veuillez contacter le support.",
        rephrasePrompt: "Essayez de reformuler votre demande ou d'utiliser une autre image.",
        checkGuidelines: "Consultez nos directives de contenu pour vous assurer que votre demande respecte nos politiques.",
        tryAgainLater: "Si le problème persiste, réessayez plus tard ou contactez le support.",
        errorLoadingImage: "Erreur de chargement de l'image",
        generalError: "Une erreur s'est produite",

        // New translations for ErrorMessage.js
        errorTitle: "Erreur",
        suggestionsTitle: "Suggestions :",

        // New translations for Footer.js
        termsOfService: "Conditions d'utilisation",
        privacyPolicy: "Politique de confidentialité",

        // New translations for GeminiChatHistory.js and Header.js
        chatHistory: "Historique des discussions",
        noChatHistoryFound: "Aucun historique de discussion trouvé.",
        untitledChat: "Discussion sans titre",
        deleteChat: "Supprimer la discussion ?",
        deleteChatConfirmation: "Êtes-vous sûr de vouloir supprimer cette discussion ? Cette action ne peut pas être annulée.",
        signIn: "Se connecter",
        signUp: "S'inscrire",

        // New translations for ImageActionButtons.js
        viewFullSize: "Voir en taille réelle",
       

        // New translations for ImageAnalysis.js
        imageAnalysis: "Analyse d'image",
        noAnalysisAvailable: "Aucune analyse disponible pour cette image.",
        performAnalysis: "Effectuer une analyse",

        // New translations for ImagePromptHistory.js
        failedToFetchPromptHistory: "Échec de la récupération de l'historique des demandes",
        failedToDeletePrompt: "Échec de la suppression de la demande",
        promptHistory: "Historique des demandes",
        noPromptHistory: "Aucun historique de demandes",
        deletePrompt: "Supprimer la demande ?",
        deletePromptConfirmation: "Êtes-vous sûr de vouloir supprimer cette demande ? Cette action ne peut pas être annulée.",

        // New translations for Login.js
        microsoftInitError: "Échec de l'initialisation de l'authentification Microsoft",
        invalidCredentials: "Identifiants invalides",
        loginError: "Une erreur s'est produite lors de la connexion",
        googleLoginError: "Une erreur s'est produite lors de la connexion Google",
        microsoftLoginError: "Une erreur s'est produite lors de la connexion Microsoft",
        login: "Se connecter",
        emailAddress: "Adresse e-mail",
        password: "Mot de passe",
        dontHaveAccount: "Vous n'avez pas de compte ?",
        createAccount: "Créer un compte",
        forgotPassword: "Mot de passe oublié ?",
        or: "OU",
        signInWithGoogle: "Se connecter avec Google",
        continueWithMicrosoftAccount: "Continuer avec un compte Microsoft",
    },
    es: {

         // New translations from Settings.js
         goToAccountSettings: "Ir a la configuración de la cuenta",
         select_theme: "Seleccionar tema",
         select_language: "Seleccionar idioma",

         // New translations from Signup.js
         createYourAccount: "Crea tu cuenta",
         googleSignupError: "Ocurrió un error durante el registro de Google",
         microsoftSignupError: "Ocurrió un error durante el registro de Microsoft",
         signUpWithGoogle: "Registrarse con Google",
         passwordRequirements: "La contraseña debe tener al menos 8 caracteres y contener al menos una letra mayúscula, una letra minúscula, un número y un carácter especial.",
         passwordsDoNotMatch: "Las contraseñas no coinciden.",
         enterBothNameAndUsername: "Por favor, ingrese tanto su nombre completo como el nombre de usuario deseado.",
         verificationCodeSent: "Por favor, ingrese el código de verificación enviado a su correo electrónico.",
         emailVerifiedSuccessfully: "¡Correo electrónico verificado con éxito! Ahora puede iniciar sesión.",
 
         // New translations from VerifyEmail.js
         verifyYourEmail: "Verifique su correo electrónico",
         emailVerifiedSuccess: "Correo electrónico verificado con éxito. Ahora puede iniciar sesión.",
         verificationFailed: "La verificación falló. Por favor, inténtelo de nuevo.",
         verificationFailedTryAgain: "La verificación falló. Por favor, inténtelo de nuevo.",
    

    chatHistory: "Historial de chat",
    noChatHistory: "No se encontró historial de chat.",
    untitledChat: "Chat sin título",
    deleteChat: "¿Eliminar chat?",
    deleteChatConfirm: "¿Estás seguro de que quieres eliminar este chat? Esta acción no se puede deshacer.",
    cancel: "Cancelar",
    delete: "Eliminar",
    resetYourPassword: "Restablece tu contraseña",
    requestReset: "Solicitar restablecimiento",
    resetPassword: "Restablecer contraseña",
    emailAddress: "Dirección de correo electrónico",
    requestResetCode: "Solicitar código de restablecimiento",
    resetCode: "Código de restablecimiento",
    newPassword: "Nueva contraseña",
    confirmNewPassword: "Confirmar nueva contraseña",
        // Sidebar items
        generateImages: "Generar imágenes",
        viewImages: "Ver imágenes",
        settings: "Configuración",
        help: "Ayuda",
        chat: "Chat",
        openAI: "OpenAI",
        gemini: "Gemini",

        // Main content headers
        aqiliBaro: "aqili Baro GPT",
        aqiliImageGenerator: "Generador de imágenes aqili",
        myGeneratedImages: "Mis imágenes generadas",
        helpContent: "Contenido de ayuda",
        aqiliOpenAI: "aqili OpenAI GPT",
        aqiliGemini: "aqili Gemini",

        // Settings
        selectTheme: "Seleccionar tema:",
        light: "Claro",
        dark: "Oscuro",
        selectLanguage: "Seleccionar idioma:",
        english: "Inglés",
        french: "Francés",
        spanish: "Español",
        german: "Alemán",

        // General
        search: "Buscar",
        loading: "Cargando...",
        error: "Ocurrió un error",
        submit: "Enviar",
        
        deleteImageConfirmation: "¿Está seguro de que desea eliminar esta imagen? Esta acción no se puede deshacer.",
        confirmSignOutAllSessions: "Confirmar cierre de sesión en todas las sesiones",
        signOutAllSessionsWarning: "¿Está seguro de que desea cerrar sesión en todas las sesiones? Esto lo desconectará de todos los dispositivos excepto el actual.",
        confirm: "Confirmar",
        viewAllImages: "Ver todas las imágenes",
        aqiliBaroGPT: "aqili baro gpt",
        aqiliOpenAIGPT: "aqili openai gpt",
        you: "Tú",
        assistant: "Asistente",
        askMeAnything: "Pregúntame lo que sea...",
        send: "Enviar",
        inputEmpty: "La entrada no puede estar vacía.",
        fetchError: "Error al obtener la respuesta del servidor.",

        enterImagePrompt: "Ingresa la solicitud de imagen",
        generateImage: "Generar imagen",
        analyzeImage: "Analizar imagen",
        generated: "Generado",
        analysis: "Análisis",
        sendingPrompt: "Enviando solicitud:",
        receivedResponse: "Respuesta recibida:",
        errorGeneratingImage: "Error al generar la imagen:",
        sendingImageForAnalysis: "Enviando imagen para análisis:",
        receivedAnalysis: "Análisis recibido:",
        errorAnalyzingImage: "Error al analizar la imagen:",
        askAQuestion: "Haz una pregunta...",
        failedToFetch: "Error al obtener:",

        generatedImage: "Imagen generada",
        errorSharing: "Error al compartir",
        sharingNotSupported: "El compartir no es compatible con su navegador.",
        generatedImageAlt: "Generado {index}",
        selectedImage: "Imagen seleccionada",
        download: "Descargar",
        share: "Compartir",
        close: "Cerrar",
        aqili: "aqili",

        newChat: "Nuevo Chat",

        companyLogo: "Logotipo de la empresa",
        welcome: "Bienvenido",
        profileImage: "Imagen de perfil",
        footerText: "©{year} aqili AI. Todos los derechos reservados.",

        account: "Cuenta",
        profile: "Perfil",
        username: "Nombre de usuario",
        email: "Correo electrónico",
        saveChanges: "Guardar cambios",

        uploadPicture: "Subir imagen",
        profilePicture: "Imagen de perfil",

        copiedToClipboard: "Copiado al portapapeles",
        failedToCopy: "Error al copiar",
        copy: "Copiar",
        copyCode: "Copiar código",
        retry: "Reintentar",
        shareFeedback: "Compartir comentarios",
        reportIssue: "Informar problema",
        failedToRetry: "Error al reintentar el mensaje",
        modelError: "El modelo no generó una respuesta. Esto puede deberse a filtros de seguridad o a la política de contenido.",

        feedback: "Comentarios",
        whatWasSatisfying: "¿Qué fue satisfactorio sobre esta respuesta?",
        feedbackDisclaimer: "Al enviar este informe, la conversación actual se enviará a aqili y se almacenará con sus comentarios para futuras mejoras de nuestros modelos. Cuando envíe comentarios y nos dé permiso, disociamos las entradas y salidas de su ID de usuario para usarlas en la capacitación y mejora de nuestros modelos.",
        feedbackSubmitted: "Comentarios enviados con éxito",

        issueType: "¿Qué tipo de problema desea informar?",
        issueDetails: "Por favor, proporcione detalles: (opcional)",
        other: "Otro",
        incorrectInformation: "Información incorrecta",
        offensiveContent: "Contenido ofensivo",
        notHelpful: "No útil",
        issueReportDisclaimer: "Al enviar este informe, la conversación actual se enviará a aqili y se almacenará con sus comentarios para futuras mejoras de nuestros modelos. Cuando envíe comentarios y nos dé permiso, disociamos las entradas y salidas de su ID de usuario para usarlas en la capacitación y mejora de nuestros modelos.",
        issueReported: "Problema informado con éxito",
        failedToSubmitFeedback: "No se pudo enviar el comentario",
        failedToReportIssue: "No se pudo informar el problema",

        
        shareNotSupported: "Compartir no es compatible con su navegador.",
        downloadImage: "Descargar imagen",
        shareImage: "Compartir imagen",
        deleteImage: "Eliminar imagen",
        closeImage: "Cerrar imagen",
        imageOptions: "Opciones de imagen",

        checkOutThisImage: "¡Echa un vistazo a esta imagen generada por IA!",
        imageTooLargeForEmail: "La imagen es demasiado grande para adjuntarla directamente. La URL de la imagen se ha copiado en su portapapeles en su lugar.",
        imageUrlCopied: "URL de la imagen copiada en el portapapeles",
        copyFailed: "Error al copiar la URL de la imagen",
        logoutConfirmTitle: "Confirmar cierre de sesión",
        logoutConfirmMessage: "¿Está seguro de que desea cerrar sesión?",

        logout: "Cerrar sesión",

        failedToFetchImages: "Error al obtener las imágenes",
        failedToFetchReports: "Error al obtener los informes de análisis",
        imageDownloadedSuccessfully: "Imagen descargada con éxito",
        failedToDownload: "Error al descargar",
        imageDeleted: "Imagen eliminada",
        failedToDelete: "Error al eliminar",
        imageAnalyzedSuccessfully: "Imagen analizada con éxito",
        failedToAnalyze: "Error al analizar",
        analysisReports: "Informes de análisis",
        noAnalysisReports: "No hay informes de análisis disponibles",
        analysisReport: "Informe de análisis",
        analyzing: "Analizando...",
        analyze: "Analizar",

        fullName: "Nombre completo",
        profileUpdatedSuccessfully: "Perfil actualizado con éxito",
        errorUpdatingProfile: "Error al actualizar el perfil",
        profilePictureUpdatedSuccessfully: "Foto de perfil actualizada con éxito",
        errorUploadingProfilePicture: "Error al subir la foto de perfil",
        errorSigningOut: "Error al cerrar sesión",
        errorDeletingAccount: "Error al eliminar la cuenta",
        system: "Sistema",
        activeAccount: "Cuenta activa",
        signedInAs: "Has iniciado sesión como:",
        signOut: "Cerrar sesión",
        sessions: "Sesiones",
        devicesCurrentlySignedIn: "Dispositivos o navegadores donde has iniciado sesión actualmente",
        signOutAllSessions: "Cerrar sesión en todas las sesiones",
        deleteAccount: "Eliminar cuenta",
        deleteAccountDescription: "Eliminar permanentemente tu cuenta y todos los datos asociados",
        confirmAccountDeletion: "Confirmar eliminación de cuenta",
        deleteAccountWarning: "¿Estás seguro de que quieres eliminar tu cuenta? Esta acción no se puede deshacer.",
        confirmDelete: "Confirmar eliminación",

        // New translations for ContentSearch.js
        contentPolicyViolation: "Su solicitud fue rechazada debido a preocupaciones de seguridad del contenido. Por favor, intente reformular su solicitud o usar imágenes diferentes. Si cree que esto es un error, puede intentarlo de nuevo o ponerse en contacto con soporte.",
        badRequestError: "Hubo un problema con su solicitud. Por favor, revise su solicitud e inténtelo de nuevo. Si el problema persiste, el sistema puede estar experimentando problemas temporales.",
        generalImageGenerationError: "Ocurrió un error al generar la imagen. Por favor, inténtelo de nuevo más tarde. Si el problema persiste, comuníquese con soporte.",
        rephrasePrompt: "Intente reformular su solicitud o usar imágenes diferentes.",
        checkGuidelines: "Revise nuestras directrices de contenido para asegurarse de que su solicitud cumpla con nuestras políticas.",
        tryAgainLater: "Si el problema persiste, inténtelo de nuevo más tarde o póngase en contacto con soporte.",
        errorLoadingImage: "Error al cargar la imagen",
        generalError: "Ocurrió un error",

        // New translations for ErrorMessage.js
        errorTitle: "Error",
        suggestionsTitle: "Sugerencias:",

        // New translations for Footer.js
        termsOfService: "Términos de servicio",
        privacyPolicy: "Política de privacidad",

        // New translations for GeminiChatHistory.js and Header.js
        
        noChatHistoryFound: "No se encontró historial de chat.",
        deleteChatConfirmation: "¿Está seguro de que desea eliminar este chat? Esta acción no se puede deshacer.",
        signIn: "Iniciar sesión",
        signUp: "Registrarse",

        // New translations for ImageActionButtons.js
        viewFullSize: "Ver tamaño completo",
       

        // New translations for ImageAnalysis.js
        imageAnalysis: "Análisis de imagen",
        noAnalysisAvailable: "No hay análisis disponible para esta imagen.",
        performAnalysis: "Realizar análisis",

        // New translations for ImagePromptHistory.js
        failedToFetchPromptHistory: "Error al obtener el historial de solicitudes",
        failedToDeletePrompt: "Error al eliminar la solicitud",
        promptHistory: "Historial de solicitudes",
        noPromptHistory: "No hay historial de solicitudes",
        deletePrompt: "¿Eliminar solicitud?",
        deletePromptConfirmation: "¿Está seguro de que desea eliminar esta solicitud? Esta acción no se puede deshacer.",

        // New translations for Login.js
        microsoftInitError: "Error al inicializar la autenticación de Microsoft",
        invalidCredentials: "Credenciales inválidas",
        loginError: "Ocurrió un error durante el inicio de sesión",
        googleLoginError: "Ocurrió un error durante el inicio de sesión de Google",
        microsoftLoginError: "Ocurrió un error durante el inicio de sesión de Microsoft",
        login: "Iniciar sesión",
        password: "Contraseña",
        
        dontHaveAccount: "¿No tienes una cuenta?",
        createAccount: "Crear cuenta",
        forgotPassword: "¿Olvidaste tu contraseña?",
        or: "O",
        signInWithGoogle: "Iniciar sesión con Google",
        continueWithMicrosoftAccount: "Continuar con una cuenta de Microsoft",
    },
    de: {

        goToAccountSettings: "Zu den Kontoeinstellungen gehen",
        select_theme: "Thema auswählen",
        select_language: "Sprache auswählen",

        // New translations from Signup.js
        createYourAccount: "Erstellen Sie Ihr Konto",
        googleSignupError: "Bei der Google-Anmeldung ist ein Fehler aufgetreten",
        microsoftSignupError: "Bei der Microsoft-Anmeldung ist ein Fehler aufgetreten",
        signUpWithGoogle: "Mit Google registrieren",
        passwordRequirements: "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
        passwordsDoNotMatch: "Passwörter stimmen nicht überein.",
        enterBothNameAndUsername: "Bitte geben Sie sowohl Ihren vollständigen Namen als auch den gewünschten Benutzernamen ein.",
        verificationCodeSent: "Bitte geben Sie den Verifizierungscode ein, der an Ihre E-Mail-Adresse gesendet wurde.",
        emailVerifiedSuccessfully: "E-Mail erfolgreich verifiziert! Sie können sich jetzt anmelden.",

        // New translations from VerifyEmail.js
        verifyYourEmail: "Bestätigen Sie Ihre E-Mail",
        emailVerifiedSuccess: "E-Mail erfolgreich verifiziert. Sie können sich jetzt anmelden.",
        verificationFailed: "Verifizierung fehlgeschlagen. Bitte versuchen Sie es erneut.",
        verificationFailedTryAgain: "Verifizierung fehlgeschlagen. Bitte versuchen Sie es erneut.",

    //General

    noChatHistory: "Kein Chat-Verlauf gefunden.",
    deleteChatConfirm: "Sind Sie sicher, dass Sie diesen Chat löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    resetYourPassword: "Setzen Sie Ihr Passwort zurück",
    requestReset: "Zurücksetzung anfordern",
    resetPassword: "Passwort zurücksetzen",
    requestResetCode: "Rücksetzungscode anfordern",
    resetCode: "Rücksetzungscode",
    newPassword: "Neues Passwort",
    confirmNewPassword: "Neues Passwort bestätigen",

        // Sidebar items
        generateImages: "Bilder generieren",
        viewImages: "Bilder ansehen",
        settings: "Einstellungen",
        help: "Hilfe",
        chat: "Chat",
        openAI: "OpenAI",
        gemini: "Gemini",

        // Main content headers
        aqiliBaro: "aqili Baro GPT",
        aqiliImageGenerator: "aqili Bildgenerator",
        myGeneratedImages: "Meine generierten Bilder",
        helpContent: "Hilfeinhalt",
        aqiliOpenAI: "aqili OpenAI GPT",
        aqiliGemini: "aqili Gemini",

        // Settings
        selectTheme: "Thema auswählen:",
        light: "Hell",
        dark: "Dunkel",
        selectLanguage: "Sprache auswählen:",
        english: "Englisch",
        french: "Französisch",
        spanish: "Spanisch",
        german: "Deutsch",

        // General
        search: "Suchen",
        loading: "Laden...",
        error: "Ein Fehler ist aufgetreten",
        submit: "Absenden",
        cancel: "Abbrechen",
        deleteImageConfirmation: "Sind Sie sicher, dass Sie dieses Bild löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
        confirmSignOutAllSessions: "Confirm Sign Out of All Sessions",
        signOutAllSessionsWarning: "Are you sure you want to sign out of all sessions? This will log you out from all devices except the current one.",
        confirm: "Bestätigen",
        viewAllImages: "Alle Bilder ansehen",
        aqiliBaroGPT: "aqili baro gpt",
        aqiliOpenAIGPT: "aqili openai gpt",
        you: "Sie",
        assistant: "Assistent",
        askMeAnything: "Fragen Sie mich...",
        send: "Senden",
        inputEmpty: "Die Eingabe darf nicht leer sein.",
        fetchError: "Fehler beim Abrufen der Antwort vom Server.",

        enterImagePrompt: "Bildaufforderung eingeben",
        generateImage: "Bild generieren",
        analyzeImage: "Bild analysieren",
        generated: "Generiert",
        analysis: "Analyse",
        sendingPrompt: "Aufforderung senden:",
        receivedResponse: "Antwort erhalten:",
        errorGeneratingImage: "Fehler beim Generieren des Bildes:",
        sendingImageForAnalysis: "Bild zur Analyse senden:",
        receivedAnalysis: "Analyse erhalten:",
        errorAnalyzingImage: "Fehler beim Analysieren des Bildes:",
        askAQuestion: "Eine Frage stellen...",
        failedToFetch: "Fehler beim Abrufen:",

        generatedImage: "Generiertes Bild",
        errorSharing: "Fehler beim Teilen",
        sharingNotSupported: "Das Teilen wird in Ihrem Browser nicht unterstützt.",
        generatedImageAlt: "Generiert {index}",
        selectedImage: "Ausgewähltes Bild",
        download: "Herunterladen",
        share: "Teilen",
        close: "Schließen",
        aqili: "aqili",

        newChat: "Neuer Chat",

        companyLogo: "Firmenlogo",
        welcome: "Willkommen",
        profileImage: "Profilbild",
        footerText: "©{year} aqili AI. Alle Rechte vorbehalten.",

        account: "Konto",
        profile: "Profil",
        username: "Benutzername",
        email: "E-Mail",
        saveChanges: "Änderungen speichern",

        uploadPicture: "Bild hochladen",
        profilePicture: "Profilbild",

        copiedToClipboard: "In die Zwischenablage kopiert",
        failedToCopy: "Kopieren fehlgeschlagen",
        copy: "Kopieren",
        copyCode: "Code kopieren",
        retry: "Wiederholen",
        shareFeedback: "Feedback teilen",
        reportIssue: "Problem melden",
        failedToRetry: "Fehler beim Wiederholen der Nachricht",
        modelError: "Das Modell hat keine Antwort generiert. Dies kann auf Sicherheitsfilter oder Inhaltsrichtlinien zurückzuführen sein.",

        feedback: "Feedback",
        whatWasSatisfying: "Was war an dieser Antwort zufriedenstellend?",
        feedbackDisclaimer: "Durch das Senden dieses Berichts wird die aktuelle Unterhaltung an aqili gesendet und mit Ihrem Feedback für zukünftige Verbesserungen unserer Modelle gespeichert. Wenn Sie Feedback senden und uns die Erlaubnis erteilen, dissociieren wir Eingaben und Ausgaben von Ihrer Benutzer-ID, um sie für das Training und die Verbesserung unserer Modelle zu verwenden.",
        feedbackSubmitted: "Feedback erfolgreich gesendet",

        issueType: "Welche Art von Problem möchten Sie melden?",
        issueDetails: "Bitte Details angeben: (optional)",
        other: "Anderes",
        incorrectInformation: "Falsche Information",
        offensiveContent: "Beleidigender Inhalt",
        notHelpful: "Nicht hilfreich",
        issueReportDisclaimer: "Durch das Senden dieses Berichts wird die aktuelle Unterhaltung an aqili gesendet und mit Ihrem Feedback für zukünftige Verbesserungen unserer Modelle gespeichert. Wenn Sie Feedback senden und uns die Erlaubnis erteilen, dissociieren wir Eingaben und Ausgaben von Ihrer Benutzer-ID, um sie für das Training und die Verbesserung unserer Modelle zu verwenden.",
        issueReported: "Problem erfolgreich gemeldet",
        failedToSubmitFeedback: "Feedback konnte nicht gesendet werden",
        failedToReportIssue: "Problem konnte nicht gemeldet werden",

        delete: "Löschen",
        shareNotSupported: "Teilen wird in Ihrem Browser nicht unterstützt.",
        downloadImage: "Bild herunterladen",
        shareImage: "Bild teilen",
        deleteImage: "Bild löschen",
        closeImage: "Bild schließen",
        imageOptions: "Bildoptionen",

        checkOutThisImage: "Schauen Sie sich dieses von KI generierte Bild an!",
        imageTooLargeForEmail: "Das Bild ist zu groß, um direkt angehängt zu werden. Die Bild-URL wurde stattdessen in Ihre Zwischenablage kopiert.",
        imageUrlCopied: "Bild-URL in die Zwischenablage kopiert",
        copyFailed: "Kopieren der Bild-URL fehlgeschlagen",
        logoutConfirmTitle: "Abmeldung bestätigen",
        logoutConfirmMessage: "Sind Sie sicher, dass Sie sich abmelden möchten?",

        logout: "Abmelden",

        failedToFetchImages: "Fehler beim Abrufen der Bilder",
        failedToFetchReports: "Fehler beim Abrufen der Analyseberichte",
        imageDownloadedSuccessfully: "Bild erfolgreich heruntergeladen",
        failedToDownload: "Herunterladen fehlgeschlagen",
        imageDeleted: "Bild gelöscht",
        failedToDelete: "Löschen fehlgeschlagen",
        imageAnalyzedSuccessfully: "Bild erfolgreich analysiert",
        failedToAnalyze: "Analyse fehlgeschlagen",
        analysisReports: "Analyseberichte",
        noAnalysisReports: "Keine Analyseberichte verfügbar",
        analysisReport: "Analysebericht",
        analyzing: "Analyse...",
        analyze: "Analysieren",

        fullName: "Vollständiger Name",
        profileUpdatedSuccessfully: "Profil erfolgreich aktualisiert",
        errorUpdatingProfile: "Fehler beim Aktualisieren des Profils",
        profilePictureUpdatedSuccessfully: "Profilbild erfolgreich aktualisiert",
        errorUploadingProfilePicture: "Fehler beim Hochladen des Profilbilds",
        errorSigningOut: "Fehler beim Abmelden",
        errorDeletingAccount: "Fehler beim Löschen des Kontos",
        system: "System",
        activeAccount: "Aktives Konto",
        signedInAs: "Sie sind angemeldet als:",
        signOut: "Abmelden",
        sessions: "Sitzungen",
        devicesCurrentlySignedIn: "Geräte oder Browser, auf denen Sie derzeit angemeldet sind",
        signOutAllSessions: "Von allen Sitzungen abmelden",
        deleteAccount: "Konto löschen",
        deleteAccountDescription: "Konto und alle zugehörigen Daten dauerhaft löschen",
        confirmAccountDeletion: "Konto löschen bestätigen",
        deleteAccountWarning: "Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
        confirmDelete: "Löschen bestätigen",

        // New translations for ContentSearch.js
        contentPolicyViolation: "Ihre Anfrage wurde aufgrund von Sicherheitsbedenken abgelehnt. Bitte versuchen Sie, Ihre Aufforderung umzuformulieren oder andere Bilder zu verwenden. Wenn Sie glauben, dass dies ein Fehler ist, können Sie es erneut versuchen oder Support kontaktieren.",
        badRequestError: "Es gab ein Problem mit Ihrer Anfrage. Bitte überprüfen Sie Ihre Aufforderung und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, erlebt das System möglicherweise vorübergehende Probleme.",
        generalImageGenerationError: "Beim Generieren des Bildes ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte den Support.",
        rephrasePrompt: "Versuchen Sie, Ihre Aufforderung umzuformulieren oder andere Bilder zu verwenden.",
        checkGuidelines: "Überprüfen Sie unsere Inhaltsrichtlinien, um sicherzustellen, dass Ihre Anfrage unseren Richtlinien entspricht.",
        tryAgainLater: "Wenn das Problem weiterhin besteht, versuchen Sie es später erneut oder kontaktieren Sie den Support.",
        errorLoadingImage: "Fehler beim Laden des Bildes",
        generalError: "Ein Fehler ist aufgetreten",

        // New translations for ErrorMessage.js
        errorTitle: "Fehler",
        suggestionsTitle: "Vorschläge:",

        // New translations for Footer.js
        termsOfService: "Nutzungsbedingungen",
        privacyPolicy: "Datenschutzrichtlinie",

        // New translations for GeminiChatHistory.js and Header.js
        chatHistory: "Chatverlauf",
        noChatHistoryFound: "Kein Chatverlauf gefunden.",
        untitledChat: "Unbenannter Chat",
        deleteChat: "Chat löschen?",
        deleteChatConfirmation: "Sind Sie sicher, dass Sie diesen Chat löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
        signIn: "Anmelden",
        signUp: "Registrieren",

        // New translations for ImageActionButtons.js
        viewFullSize: "Vollbild ansehen",
       

        // New translations for ImageAnalysis.js
        imageAnalysis: "Bildanalyse",
        noAnalysisAvailable: "Keine Analyse für dieses Bild verfügbar.",
        performAnalysis: "Analyse durchführen",

        // New translations for ImagePromptHistory.js
        failedToFetchPromptHistory: "Fehler beim Abrufen des Aufforderungsverlaufs",
        failedToDeletePrompt: "Fehler beim Löschen der Aufforderung",
        promptHistory: "Aufforderungsverlauf",
        noPromptHistory: "Kein Aufforderungsverlauf",
        deletePrompt: "Aufforderung löschen?",
        deletePromptConfirmation: "Sind Sie sicher, dass Sie diese Aufforderung löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",

        // New translations for Login.js
        microsoftInitError: "Fehler bei der Initialisierung der Microsoft-Authentifizierung",
        invalidCredentials: "Ungültige Anmeldeinformationen",
        loginError: "Bei der Anmeldung ist ein Fehler aufgetreten",
        googleLoginError: "Bei der Google-Anmeldung ist ein Fehler aufgetreten",
        microsoftLoginError: "Bei der Microsoft-Anmeldung ist ein Fehler aufgetreten",
        login: "Anmelden",
        emailAddress: "E-Mail-Adresse",
        password: "Passwort",
        dontHaveAccount: "Sie haben noch kein Konto?",
        createAccount: "Konto erstellen",
        forgotPassword: "Passwort vergessen?",
        or: "ODER",
        signInWithGoogle: "Mit Google anmelden",
        continueWithMicrosoftAccount: "Mit Microsoft-Konto fortfahren",
    },
};
